.Ynfg__login-form{
    max-width: 45rem;
    box-shadow: var(--bs22);
    padding: 0;
}
.Ynfg__login-form form{
    padding: 1.5rem;
}
.Ynfg__login-logo{
    width: 12rem;
    padding: .5rem;
}