:root{
  --color-60: #FFF;
  --color-30: #3d3f40;
  --color-10: #004ea2;

  --color-30-v1: #c1c6c8;
  --color-30-v2: #f1f2f3;

  --calendar-grid-width: 902px;

  --bs22: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.Ynfg__app, .Ynfg__page-container{
  max-width: 100vw;
  min-height: 100vh; 
}
.Ynfg__page-container.layout-1{
  display: grid;
  grid-template-rows: auto 1fr auto;
  animation: homeEntry 0.4s ease-in;
}
.Ynfg__page-container.layout-2{
  display: grid;
  grid-template-rows: 1fr auto;
}

@keyframes homeEntry {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

/** LOGO **/
.Ynfg__logo{
  padding: 1.2rem;
  position: relative;
  animation: entryLogo .7s ease backwards .4s;
}
@keyframes entryLogo {
  0%{
    transform: translateX(-10px);
    opacity: 0;
  }
  80%{
    opacity: 1;
    transform: translateX(5px);
  }
  100%{
    opacity: 1;
    transform: translateX(0px);
  }
}
.Ynfg__logo-img{
  max-width: 160px;
  display: block;
  margin:auto;
}
.Ynfg__logo-subtext{
  font-size: 0.8rem;
  letter-spacing: 0.3rem;
  font-weight: bold;
  width: 85%;
  text-align: right;
  display: inline-block;
  position: absolute;
  bottom: .25rem;
}
/** END LOGO **/

/** COMMON LAYOUT **/
main{
  background-color: var(--color-60);
}
section{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.Ynfg__title{
  text-align: center;
  padding: 1.5rem;
  font-weight: 500;
  color: var(--color-60);
  background-color: var(--color-30);
  border-radius: .0;
}
.modal{
  width: fit-content;
  --bs-modal-width: 80rem !important;
}
.modal-body{
  padding-bottom: 2rem !important;
}
/** END COMMON LAYOUT **/