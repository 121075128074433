@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Prompt:wght@500&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{
  /*font-family: 'Alice', serif;*/
}
body, #root{
  max-width: 100vw;
  min-height: 100vh;
}
a:link, a:visited{
  text-decoration: none;
}
h1, h2, h3, h4, button{
  font-family: 'Prompt', sans-serif !important;
}
span, p, input, select, textarea{
  font-family: 'Arvo', serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
