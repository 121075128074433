/** NAVBAR **/
.Ynfg__navbar{
    background-color: var(--color-30-v1);
    box-shadow: var(--bs22);
    padding: .5rem;
}
.Ynfg__navbar-link{
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 1.6rem !important;
  color: var(--color-60);
}
.Ynfg__navbar-link:hover{
  color: var(--color-10);
}
.Ynfg__navbar .navbar-brand{
  padding: .9rem;
}
.Ynfg__navbar form{
  padding: 1.1rem;
}
.Ynfg__navbar form>*{
  font-size: 1.5rem;
}
.Ynfg__navbar button{
  color: var(--color-10);
  border-color: var(--color-10);
  padding: .8rem;
}
.Ynfg__navbar button:hover{
  color: var(--color-60);
  background-color: var(--color-10);
  border-color: var(--color-10);
}
.Ynfg__navbar .navbar-toggler-icon{
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27%23E0E0E2%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');
}
/** END NAVBAR **/

.Ynfg__navbar-user-menu, .Ynfg__navbar-user-menu .dropdown-menu {
  width: 15rem;
}
.Ynfg__navbar-user-menu button {
  width: 100%;
  font-size: 1.5rem;
  background-color: var(--color-30-v2);
  border-radius: .5rem;
  border: 1px solid var(--color-30-v1);
  color: var(--color-30);
}
.Ynfg__navbar-user-menu button:hover {
  background-color: var(--color-30-v1);
  color: var(--color-30);
  border: 1px solid var(--color-30-v2);
}
.Ynfg__navbar-user-menu ul {
  width: 100%;
  background-color: var(--color-30-v2);
  border-radius: .5rem;
  border: 1px solid var(--color-30-v1);
  color: var(--color-30);
}
.Ynfg__navbar-user-menu ul a:hover {
  background-color: var(--color-30-v1);
  color: var(--color-30);
}
.Ynfg__navbar-user-menu .dropdown-menu .dropdown-item{
  font-size: 1.4rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}
.Ynfg__navbar-user-menu .dropdown-menu .dropdown-item>i{
  margin-right: .4rem;
}