/** FORM **/
.Ynfg__generic-form{
  max-width: 75rem;
  box-shadow: var(--bs22);
  padding: 0;
}
.Ynfg__generic-form form{
  padding: 1.5rem;
}
.form-group {
  padding: .8rem;
}
.form-group label{
  font-size: 1.5rem;
  font-weight: 500;
}
input.Ynfg__form-field, select.Ynfg__form-field, textarea.Ynfg__form-field{
  font-size: 1.5rem;
  min-height: 3.7rem;
  background-color: var(--color-60);
}
input.Ynfg__form-field-checkbox{
  font-size: 1.5rem;
  display: block;
  margin-left: .4rem !important;
  float: none !important;
  margin-top: 0;
}
input.Ynfg__form-field:focus, select.Ynfg__form-field:focus, textarea.Ynfg__form-field:focus{
  background-color: var(--color-60-bright);
  border-color: var(--color-60-bright);
  box-shadow: 0 0 0 0.09rem var(--color-10);
}
.Ynfg__form-field-wrapper span{
  width: 3.7rem;
  background-color: var(--color-30);
  color: var(--color-60);
}
.Ynfg__form-field-wrapper span>i{
  width: 100%;
}
.Ynfg__form-button,
.Ynfg__form-button:link,
.Ynfg__form-button:visited{
  width: max-content;
  max-width: 12rem;
  display: block;
  margin-left: auto;
  margin-top: 1rem;
  padding: .5rem;
  background-color: var(--color-10);
  color: var(--color-60);
  font-size: 1.6rem;
  transition: all 0.2s ease-out;
}
.Ynfg__form-button:hover{
  box-shadow: 0px 3px 5px 0px black;
  transform: translateY(-4px);
  color: var(--color-10);
}
.Ynfg__form-button:active,
.Ynfg__form-button:focus{
  transform: translateY(-1px);
  box-shadow: 0px 1px 3px 0px black;
}
#inputGroupPrepend{
    width: 3.5rem;
    background-color: var(--color-30-v1);
    color: var(--color-30);
}
#inputGroupPrepend>i{
    flex-basis: 100%;
    font-size: 1.4rem;
}
.invalid-feedback{
    display: block !important;
}
/** END FORM **/