.calendar-container{
    padding: 1rem;
    font-size: 1.2rem;
    overflow-x: auto;
}
.calendar-container .pagination{
    padding: 1rem;
    font-size: 1.8rem;
    justify-content: center;
    flex-direction: row;
}
.calendar-container .pagination> .pagination-content {
    padding: .6rem;
}
.calendar-container .pagination> .pagination-content button>i{
    font-size: 1.5rem;
}
.calendar-container .pagination .range> p {
    text-align: center;
    font-size: 1.2rem;
}
.w-calendar-container{
    display: grid;
    grid-template-columns: 10rem auto;
    grid-template-rows: auto;
    gap: 0px 0px;
    position: relative;
    grid-template-areas: 
        ". header" 
        "timeslots main";
    overflow-x: auto;
}

.w-calendar-container li{
    list-style: none;
    margin: 0;
}

.w-calendar-container ul{
    padding: 0;
    margin: 0;
}

.w-calendar-container .header{
    grid-area: header;
    text-align: center;
    font-size: 1.35rem;
    font-weight: 500;
    padding: .5rem;
    background-color: var(--color-30-v1);
    color: var(--color-30);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.16) -1px 0px 4px;
    min-width: var(--calendar-grid-width);
}

.w-calendar-container .header>.weekdays>ul, 
.w-calendar-container .header>.weekdayNo>ul{
    display: grid;
    grid-template-columns: repeat(7 , 1fr);
}

.w-calendar-container .timeslots-container{
    grid-area: timeslots;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--color-30-v1);
    box-shadow: rgba(0, 0, 0, 0.16) -1px 0px 4px;
    text-align: center;
    max-width: calc(var(--calendar-grid-width) / 7);
}
.timeslots ul{

}
.timeslots li{
    font-size: 1.4rem;
    min-height: 60px;
    height: 60px;
}
.timeslots li::after{
    position: absolute;
    height: 1px;
    background-color: var(--color-30);
    min-width: calc(var(--calendar-grid-width) + min(calc(var(--calendar-grid-width) / 7), 10rem));
    width: 100%;
    content: '';
    left: 0px;
}
.timeslots li>label{
    display: inline-block;
    padding: .25rem;
    font-weight: 600;
}

.w-calendar-container .event-container{
    display: grid;
    position: relative;
    min-width: var(--calendar-grid-width);
    grid-area: main;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(68, 1fr); /*customize on js : 22 - 6 => 17 * 4 (1/4 for each hour) = 68 slot*/
}

.daygrid{
    background-color: var(--color-10);
    opacity: 0.35;
    z-index: 1;
}
.daygrid.dayDisabled{
    background-color: rgb(41, 40, 40) !important;
}
.hourGrid{
    border-right: 2px solid #ffffff52; 
}

.slot{
    opacity: .85;
    position: absolute;
    min-height: 30px !important; /*half an hour*/
    background-color: brown;
    border-radius: .3rem;
    z-index: 5;
    color: white;
    padding: .2rem;
    cursor: pointer;
}
.slot:hover{
    opacity: 1;
}
.slot>.slot-header{
    display: flex;
    flex-direction: row;
    font-size: 1.6rem;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: .15rem;
    background-color: #ffffffd9;
    color: var(--color-30);
    border-radius: inherit;
    opacity: 1;
    padding: 0 0rem 0 .25rem;
}
.slot>.slot-header label{
    margin-right: .25rem;
}
.slot>.slot-header a{
    cursor: pointer;
}
.slot>.slot-header>*:nth-child(2){
    margin-left: auto; 
} 
.slot>.slot-header>a{
    padding: 0rem .35rem .125rem .8rem;
    transition: all .2s ease-in-out;
    color: #ffffffd9;
    background-color: #3d3f4085;
} 
.slot>.slot-header>a:hover{
    padding: 0rem .4rem .125rem .85rem;
    color: var(--color-60);
    background-color: var(--color-30);
}